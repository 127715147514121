import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [~3],
		"/account-blocked": [4],
		"/auth/default-password": [~5],
		"/auth/login": [6],
		"/auth/logout": [7],
		"/auth/password-reset": [8],
		"/auth/password-reset/success": [10],
		"/auth/password-reset/[user]/[token]": [~9],
		"/maintenance": [11],
		"/portal": [~12,[2]],
		"/portal/billing": [~13,[2]],
		"/portal/shop": [~14,[2]],
		"/portal/tickets": [~15,[2]],
		"/portal/vouchers/layout": [~16,[2]],
		"/portal/vouchers/statistics": [~17,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';